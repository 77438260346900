function diplomas(supportsTouch) {
    const diplomas = document.querySelectorAll('.diplomas__item img'),
          diplomasOverlay = document.querySelector('.diplomas__overlay'),
          diplomasModal = document.querySelector('.diplomas__modal'),
          diplomasModalClose = document.querySelector('.diplomas__modal-close');

    function showDiploma(diploma) {
        diploma.addEventListener('click', () => {
            let img = diploma.cloneNode();

            diplomasOverlay.classList.add('active');
            diplomasModal.append(img);
            diplomasModal.classList.add('active');

            //for touch
            if (supportsTouch === true) {
                if (diplomasModal.querySelector('img')) {
                    diplomasModal.querySelector('img').style.cssText = `
                        max-height: 100vh;
                        max-width: 100vw;
                    `;
                } 

                diplomasModalClose.style.display = 'none';
            } 
        });
    }

    function hideDiploma() {
        diplomasOverlay.classList.remove('active');
        diplomasModal.classList.remove('active');
        
        if (diplomasModal.querySelector('img')) {
            diplomasModal.querySelector('img').remove();
        }
    }

    diplomas.forEach(function(diploma) {
        showDiploma(diploma);
    });

    if (diplomasModalClose) {
        diplomasModalClose.addEventListener('click', () => {
            hideDiploma();
        });
    }

    if (diplomasOverlay) {
        diplomasOverlay.addEventListener('click', () => {
            hideDiploma();
        });
    }
}

export default diplomas;