function slider() {
    const slider = document.querySelector('.portfolio__slider'),
          items = document.querySelectorAll('.portfolio__item'),
          sliderImg = document.querySelectorAll('.portfolio__item-img'),
          sliderDivider = document.querySelector('.portfolio__divider'),
          sliderDesc = document.querySelectorAll('.portfolio__item-description'),
          sliderDescWrap = document.querySelector('.portfolio__description-wrapper'),
          nextBtn = document.querySelector('.portfolio__slider-next'),
          prevBtn = document.querySelector('.portfolio__slider-prev'),
          linkBtn = document.querySelectorAll('.portfolio__slider-link');

    if (slider) {
        let slideIndex = 1,
            paused = false;
    
        function showSlides(n) {
            if (n > items.length) {
                slideIndex = 1;
            }
    
            if (n < 1) {
                slideIndex = items.length;
            }
    
            sliderImg.forEach(item => {
                item.classList.add('increase');
                item.style.display = 'none';
            });

            linkBtn.forEach(item => {
                item.style.display = 'none';
            });
    
            sliderDivider.classList.add('increase');
            sliderDivider.style.display = 'none';
            sliderDescWrap.appendChild(sliderDivider);

            sliderDesc.forEach(item => {
                sliderDescWrap.appendChild(item);
                item.classList.add('fadeIn');
                item.style.display = 'none';
            });
    
            sliderImg[slideIndex - 1].style.display = 'block';
            linkBtn[slideIndex - 1].style.display = 'block';
            sliderDivider.style.display = 'block';
            sliderDesc[slideIndex - 1].style.display = 'block';
    
            if (sliderDesc[slideIndex - 1].clientHeight > 96) {
                sliderDesc[slideIndex - 1].style.overflow = 'auto';
            }
        }
    
        showSlides(slideIndex);
    
        function plusSlides(n) {
            showSlides(slideIndex += n);
        }
    
        prevBtn.addEventListener('click', () => {
            plusSlides(-1);
        });
    
        nextBtn.addEventListener('click', () => {
            plusSlides(1);
        });
    
        function activateAnimation() {
            paused = setInterval(function() {
                plusSlides(1);
            }, 5000);
        }
        activateAnimation();
    
        slider.addEventListener('mouseenter', () => {
            clearInterval(paused);
        });
    
        slider.addEventListener('mouseleave', () => {
            activateAnimation();
        });
    }
};

export default slider;