function cookie() {
    let cookieDate = localStorage.getItem('cookieDate');
    let policyCookie = document.querySelector('.policy__cookie');
    let cookieBtn = policyCookie.querySelector('.policy__cookie-btn');

    if (!cookieDate || (+cookieDate + 31536000000) < Date.now()){
        policyCookie.style.display = 'flex';
    }

    cookieBtn.addEventListener('click', function(){
        localStorage.setItem('cookieDate', Date.now());
        policyCookie.style.display = 'none';
    })
}

export default cookie;