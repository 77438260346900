function form() {
    const form = document.querySelector('.contacts__form'),
          reset = form.querySelector('[type="reset"]'),
          contactsField = document.querySelectorAll('.contacts__field'),
          name = document.querySelector('#name'),
          email = document.querySelector('#email'),
          message = document.querySelector('#message'),
          agree = document.querySelector('#agree');
          
    let surname = document.querySelector('#surname').value,
        statusMessage = document.createElement('div'),
        statusMessageValue = {
            loading: '<p style="color: var(--one-color)">Идет отправка...</p>',
            success: '<p style="color: #22AC37">Ваше сообщение отправлено!</p>',
            failure: '<p style="color: #FF0000">Что-то пошло не так...</p>'
        };

    statusMessage.classList.add('contacts__status');

    form.noValidate = true;

    agree.addEventListener('click', () => agree.style.outline = 'none');

    function inputBlure(inputBlure) {
        inputBlure.addEventListener('blur', () => {
            if ((inputBlure === name && !/^[а-яa-zА-ЯA-ZёЁ0-9_-]|\s{1,35}$/.test(name.value)) || 
            (inputBlure === name && /^[ ]+$/.test(name.value)) ||
            (inputBlure === email && !/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/i.test(email.value)) || 
            (inputBlure === message && /^[ ]+$/.test(message.value)) ||
            (inputBlure === message && message.value == '')) {
                inputBlure.nextElementSibling.style.color = '#FF0000';
            }
        });
    }

    function inputDefault(inputDefault) {
        inputDefault.nextElementSibling.style.color = 'var(--one-color)';

        agree.style.outline = 'none';

        if (form.querySelector('.contacts__status')) {
            statusMessage.classList.remove('increase');
            statusMessage.classList.add('decrease');
            setTimeout (() => {
                statusMessage.innerHTML = '';
            }, 600);
        }
    }

    function inputFocus(inputFocus) {
        inputFocus.addEventListener('focus', () => {
            inputDefault(inputFocus);
        });
    }

    contactsField.forEach(function(item) {
        inputBlure(item);
        inputFocus(item);
    });

    function sendForm(elem) {
        elem.addEventListener('submit', function(e) {
            e.preventDefault();

            form.append(statusMessage);
            statusMessage.classList.remove('decrease');
            statusMessage.classList.add('increase');
        
            if (surname.length === 0 &&
            /^[а-яa-zА-ЯA-ZёЁ0-9_-]|\s{1,35}$/.test(name.value) &&
            !/^[ ]+$/.test(name.value) &&
            /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/i.test(email.value) &&
            !/^[ ]+$/.test(message.value) &&
            message.value !== '' &&
            agree.checked) {
                let data = `name=${name.value}&email=${email.value}&message=${message.value}&surname=${surname}&agree=${agree.checked}`;
        
                let request = new XMLHttpRequest();

                request.open('POST', '../mail.php');
                request.setRequestHeader ('Content-Type', 'application/x-www-form-urlencoded');

                request.send(data);

                statusMessage.innerHTML = statusMessageValue.loading;
            
                request.addEventListener('readystatechange', () => {
                    if (request.readyState < 4) {
                        statusMessage.innerHTML = statusMessageValue.loading;
                    } else if (request.readyState === 4 && request.status == 200) {
                        statusMessage.innerHTML = statusMessageValue.success;
                
                        contactsField.forEach(function(item) {
                            setTimeout (() => {
                                item.value = '';
                                inputDefault(item)
                            }, 3000);
                        });
                    } else {
                        statusMessage.innerHTML = statusMessageValue.failure;
                    }
                });              
            } else {
                contactsField.forEach(function(item) {
                    checkInput(item);
                });
            }

            function checkInput(checkInput) {
                if (surname.length !== 0) {
                    statusMessage.innerHTML = statusMessageValue.failure;
                } else if (checkInput.value == '') {
                    checkInput.nextElementSibling.style.color = '#FF0000';
                    statusMessage.innerHTML = '<p style="color: #FF0000">Вы не заполнили все поля!</p>';
                }  else if ((checkInput === name && !/^[а-яa-zА-ЯA-ZёЁ0-9_-]|\s{1,35}$/.test(name.value)) || 
                (checkInput === name && /^[ ]+$/.test(name.value)) ||
                (checkInput == message && /^[ ]+$/.test(message.value)) ||
                (checkInput === email && !/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/i.test(email.value))) {
                    checkInput.nextElementSibling.style.color = '#FF0000';
                    statusMessage.innerHTML = '<p style="color: #FF0000">Не все поля введены корректно!</p>';
                } 
                else if (!agree.checked) {
                    agree.style.outline = '2px solid #FF0000';
                    statusMessage.innerHTML = '<p style="color: #FF0000">Вы не поставили галочку!</p>';
                }
            }
        });
    }

    sendForm(form);

    reset.addEventListener('click', () => {
        contactsField.forEach(function(item) {
            inputDefault(item);
            item.nextElementSibling.style.color = 'var(--one-color)';
        });
    });
}

export default form;