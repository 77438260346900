function touch(supportsTouch) {
    function menuLinkTouch(link) {
        link.addEventListener('mouseenter', () => {
            link.style.color = '#FFF';
        });
    }

    function btnTouch(button) {
        button.addEventListener('mouseenter', () => {
            button.style.boxShadow = '0px 4px 4px rgba(0, 0, 0, 0.15)';
            button.style.color = 'var(--one-color)';
        });
    }

    function linkTouch(link) {
        link.addEventListener('mouseenter', () => {
            link.style.color = 'var(--one-color)';
        });
    }

    function diplomasItemTouch(diploma) {
        diploma.addEventListener('mouseenter', () => {
            diploma.style.filter = 'grayscale(0)';
        });
    }
    
    function btnSliderTouch(btn) {
        btn.addEventListener('mouseenter', () => {
            btn.style.opacity = '.6';
        });
    }

    if (supportsTouch === true) {
        document.querySelectorAll('.menu__link a').forEach(item => {
            menuLinkTouch(item);
        });

        document.querySelectorAll('.btn').forEach(item => {
            btnTouch(item);
        });

        document.querySelectorAll('.link').forEach(item => {
            linkTouch(item);
        });

        document.querySelectorAll('.diplomas__item img').forEach(item => {
            diplomasItemTouch(item);
        });
        
        document.querySelectorAll('.portfolio__slider-btn').forEach(item => {
            btnSliderTouch(item);
        });

        document.querySelector('.pageup svg path').style.fill = 'var(--one-color)';

        document.querySelectorAll('.menu__link').forEach(item => {
            function toggleActive() {
                if (window.innerWidth > 992) {
                    item.classList.remove('active');
                } else {
                    item.classList.add('active');
                }
            }

            toggleActive();

            window.addEventListener('resize', () => {
                toggleActive();
            });
        });
    } 
}

export default touch;