import touch from './modules/touch';
import theme from './modules/theme';
import menu from './modules/menu';
import diplomas from './modules/diplomas';
import slider from './modules/slider';
import scrolling from './modules/scrolling';
import cookie from './modules/cookie';
import form from './modules/form';

window.addEventListener('DOMContentLoaded', () => {
    `use strict`;
    
    const supportsTouch = ('ontouchstart' in document.documentElement);

    touch(supportsTouch);
    theme();
    menu(supportsTouch);
    diplomas(supportsTouch);
    slider();
    scrolling();
    cookie();
    form();
});