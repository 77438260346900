function theme() {
    const moon = document.querySelector('.menu__moon'),
          sun = document.querySelector('.menu__sun'),
          html = document.querySelector('html');

    function dark() {
        html.style.setProperty("--one-color", "#FFF");
        html.style.setProperty("--two-color", "#000");
        html.style.setProperty("--background-color", "#17212B");
        html.style.setProperty("--overlay-color", "#000");
        html.style.setProperty("--slider-color", "#000");
        moon.style.display = 'none';
        sun.style.display = 'block';
    }

    function light() {
        html.style.setProperty("--one-color", "#000");
        html.style.setProperty("--two-color", "#FFF");
        html.style.setProperty("--background-color", "#FFF");
        html.style.setProperty("--overlay-color", "#BEE8FB");
        html.style.setProperty("--slider-color", "#BCBCBC");
        moon.style.display = 'block';
        sun.style.display = 'none';  
    }

    if (localStorage.getItem('dark')) {
        dark();
    }

    moon.addEventListener('click', () => {  
        dark();
        localStorage.setItem('dark', true);
    });

    sun.addEventListener('click', () => {  
        light();
        localStorage.removeItem('dark');
    });
}

export default theme;