function menu(supportsTouch) {
    const hamburger = document.querySelector('.menu__hamburger'),
          hamburgerSpan = document.querySelectorAll('.menu__hamburger span'),
          menuBlock = document.querySelector('.menu__block'),
          menuOverlay = document.querySelector('.menu__overlay'),
          closeElem = document.querySelector('.menu__close'),
          menuSocial = document.querySelector('.menu__social'),
          menuLink = document.querySelectorAll('.menu__link');

    function hamburgerEvents(hamburgerSpan) {
        hamburger.addEventListener('mouseover', () => {  
            hamburgerSpan.style.background = '#FFA501';
        });

        hamburger.addEventListener('mouseout', () => {  
            hamburgerSpan.style.background = '#FFF';
        });

        hamburger.addEventListener('mousedown', () => {  
            hamburgerSpan.style.background = '#FFB734';
        });

        hamburger.addEventListener('mouseup', () => {  
            hamburgerSpan.style.background = '#FFF';
        });
    }

    if (supportsTouch === false) {
        hamburgerSpan.forEach(function(item) {
            hamburgerEvents(item);
        });
    }  

    function menuBlockShow() {
        menuBlock.classList.add('active');
        menuOverlay.classList.add('active');
    }

    function menuBlockClose() {
        menuBlock.classList.remove('active');
        menuOverlay.classList.remove('active');
    }

    window.addEventListener('resize', () => {
        if (window.innerHeight < 550) {
            menuSocial.style.display = 'none';
        } else {
            menuSocial.style.display = 'flex';
        }

        if (window.innerWidth > 992) {
            menuBlockClose();
        }
    });

    hamburger.addEventListener('click', menuBlockShow);
    closeElem.addEventListener('click', menuBlockClose);
    menuOverlay.addEventListener('click', menuBlockClose);
    menuLink.forEach(link => {
        link.addEventListener('click', () => {
            setTimeout(menuBlockClose, 200);
            link.querySelector('a').click();
        });
    });
}

export default menu;